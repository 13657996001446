import { nonEmptyString } from "@/util/string";
import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { RootState } from "@services/store";
import isPlainObject from "lodash/isPlainObject";
import has from "lodash/has";
import get from "lodash/get";
import set from "lodash/set";

type Errors = Record<string, string[]>;

export function State(): Errors {
  return {};
}

const state: Errors = State();

const namespaced: boolean = true;

/* Actions */
export const actions: ActionTree<Errors, RootState> = {
  init({ dispatch }) {
    dispatch("reset");
  },
  update({ commit }, record: Record<string, string[]>) {
    if (isPlainObject(record)) {
      commit("update", record);
    }
  },
  reset({ commit }) {
    commit("reset", State());
  },
  "reset-error": ({ commit }, path: string) => {
    if (nonEmptyString(path)) {
      commit("update", { path, messages: [] });
    }
  }
};

/* Mutations */
export const mutations: MutationTree<Errors> = {
  update(
    state,
    {
      path,
      messages,
      options
    }: { path: string; messages: string[]; options?: Record<string, unknown> }
  ) {
    const key =
      nonEmptyString(path) && has(state, path.trim()) ? path.trim() : "";

    const errors = messages.filter(nonEmptyString);

    if (nonEmptyString(key)) {
      const existingErrors = get(state, key);
      options && isPlainObject(options) && options.replace
        ? set(state, key.trim(), errors)
        : set(state, key.trim(), (existingErrors || []).concat(errors));
    }
  },
  reset(state) {
    Object.keys(state).forEach((key: string) => delete state[key]);
  }
};

/* Getters */
export const getters: GetterTree<Errors, RootState> = {
  state: (state: Errors) => state,
  select: (state: Errors) => (path: string) => {
    const errors = nonEmptyString(path) ? get(state, path.trim()) : [];
    return Array.isArray(errors) ? errors.filter(nonEmptyString) : [];
  }
};

const store: Module<Errors, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default store;
