import Vue from "vue";
import VueRouter, { Route, RawLocation } from "vue-router";
import store from "@services/store";
import Login from "@views/pages/Login.vue";
import { getCSRFToken } from "../actions";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/registration",
    name: "registration",
    component: () => import("@views/pages/Registration.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@views/pages/PasswordReset.vue")
  },
  {
    path: "/password-reset-request",
    name: "password-reset-request",
    component: () => import("@views/pages/PasswordResetRequest.vue")
  },
  {
    path: "/",
    name: "home",
    redirect: "/create-referral"
  },
  {
    path: "/create-referral",
    name: "create-claim",
    component: () => import("@views/pages/create-claim/Main.vue"),
    meta: {
      uberOnly: true
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@views/pages/admin/Main.vue"),
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/callback-request-queue",
    name: "callbacks",
    component: () => import("@views/pages/callback-queue/Main.vue"),
    meta: {
      rainnOnly: true
    }
  },
  {
    path: "/submit-referral",
    name: "submit-claim",
    component: () => import("@views/pages/submit-claim/Main.vue"),
    meta: {
      rainnOnly: true
    }
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("@views/pages/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes
});

router.beforeEach(
  async (to: Route, _: Route, next: (to?: RawLocation) => void) => {
    const isAuthenticated = store.getters["session/authenticated"];
    if (
      isAuthenticated &&
      to.name &&
      ["registration", "password-reset", "password-reset-request"].includes(
        to.name
      )
    ) {
      await exit();
    }

    // Default to empty object to prevent NPE when trying to obtain Roles.
    const { Roles: roles = [] } = store.getters["session/user"] || {};
    // (to.meta.VALUE || false) ensures that routes that do not require a specific
    // role are not prevented from being accessed as the meta properties only
    // exist on guarded routes.
    const adminOnly: boolean = (to.meta.adminOnly || false) === true;
    const rainnOnly: boolean = (to.meta.rainnOnly || false) === true;
    const uberOnly: boolean = (to.meta.uberOnly || false) === true;
    // Ensure that at least a single role exists, if one does not, default to -1
    // to indicate that the current role is unknown or that the user is
    // unauthenticated.
    const role: number = roles.length > 0 ? roles[0].ID : -1;
    const isAdmin: boolean = (roles.length > 0 && role === 1) || role === 3;
    const isRainn: boolean = (roles.length > 0 && role === 1) || role === 2;
    const isUber: boolean = (roles.length > 0 && role === 3) || role === 4;
    // Check for each route guard and user role at once to simplify if/else logic.
    // Should specific actions be required per role, make this check occur at the
    // role level within the if/else statement below rather than all at once.
    const missingRequiredAuthentication =
      (adminOnly && !isAdmin) ||
      (rainnOnly && !isRainn) ||
      (uberOnly && !isUber);

    if (missingRequiredAuthentication) {
      await exit();
      // next("/login");
    } else {
      next();
    }
  }
);

export default router;

async function exit() {
  try {
    const token = await getCSRFToken();
    await axios({
      url: `/api/v1/users/authentication`,
      method: "DELETE",
      headers: {
        "X-CSRF-Token": token
      }
    });
  } catch (error) {
    console.error("logout action on navigation route guard failed", error);
  }
}
