import { User } from "./../../../datatypes/User";
import { Module, GetterTree, ActionTree, MutationTree } from "vuex";
import { RootState } from "../../../services/store";
import isPlainObject from "lodash/isPlainObject";
import Vue from "vue";

interface Session {
  authenticated: boolean;
  user: User | null;
}

export function State(): Session {
  return { authenticated: false, user: null };
}

const state: Session = State();

const namespaced: boolean = true;

/* Actions */
export const actions: ActionTree<Session, RootState> = {
  init({ dispatch }) {
    dispatch("reset");
  },
  updateStatus({ commit }, authenticated: boolean = false) {
    commit("updateStatus", authenticated);
  },
  updateUser({ commit }, user: User) {
    if (isPlainObject(user)) {
      commit("updateUser", user);
    }
  },
  update({ commit }, payload: Partial<Session>) {
    if (isPlainObject(payload)) {
      commit("update", payload);
    }
  },
  reset({ commit }) {
    commit("reset", State());
  }
};

/* Mutations */
export const mutations: MutationTree<Session> = {
  updateStatus(state, authenticated: boolean) {
    if (typeof authenticated === "boolean") {
      Vue.prototype.$set(state, "authenticated", authenticated);
    }
  },
  updateUser(state, user: User) {
    if (isPlainObject(user)) {
      Vue.prototype.$set(state, "user", { ...state.user, ...user });
    }
  },
  update(state, payload: Partial<Session>) {
    if (typeof payload.authenticated === "boolean") {
      Vue.prototype.$set(state, "authenticated", payload.authenticated);
    }

    if (isPlainObject(payload.user)) {
      Vue.prototype.$set(state, "user", { ...state.user, ...payload.user });
    }
  },
  reset(state, payload?: Session) {
    if (payload && isPlainObject(payload)) {
      Vue.prototype.$set(state, "authenticated", payload.authenticated);
      Vue.prototype.$set(state, "user", payload.user);
      return;
    }

    Vue.prototype.$set(state, "authenticated", false);
    Vue.prototype.$set(state, "user", null);
  }
};

/* Getters */
export const getters: GetterTree<Session, RootState> = {
  user: (state: Session) => state.user,
  role: (state: Session) =>
    state.user &&
    isPlainObject(state.user) &&
    Array.isArray(state.user.Roles) &&
    state.user.Roles.length
      ? state.user.Roles[0]
      : null,
  authenticated: (state: Session) => state.authenticated
};

const store: Module<Session, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};

export default store;
