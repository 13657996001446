import { ClaimRequest } from "./../datatypes/Claim";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { Callback } from "./../datatypes/Callback";
import { Category } from "./../datatypes/Category";
import { User } from "./../datatypes/User";
import axios, { AxiosResponse, AxiosError } from "axios";
import { Role } from "@/datatypes/Role";
import { Claim } from "@/datatypes/Claim";

export function extractErrorMessage(error: Error | AxiosError) {
  console.error("XHR ERROR", error);
  const serverErrorMessageTextIndicators = [
    "request failed with status code 500",
    "got non-error:",
    "500",
    "server error",
    "network error"
  ];

  if (!isEmpty(get(error, "response.data"))) {
    const statusCode = get(error, "statusCode") || get(error, "status");
    if (statusCode === 500) {
      return "Internal server error";
    }
    const message = String(
      get(error, "response.data.problems") ||
        get(error, "response.data.code") ||
        get(error, "response.data.message") ||
        get(error, "response.data")
    );

    return serverErrorMessageTextIndicators.includes(message.toLowerCase())
      ? "Internal server error"
      : message;
  } else {
    return typeof error.message === "string" &&
      !isEmpty(error.message.trim()) &&
      !serverErrorMessageTextIndicators.includes(error.message.toLowerCase())
      ? error.message.trim()
      : "Internal server error";
  }
}

export function getusers(
  success?: (users: User[]) => void,
  failure?: (err: Error[]) => void
) {
  return new Promise<User>((resolve, reject) => {
    axios({
      url: "/api/v1/users",
      method: "GET"
    })
      .then((response: AxiosResponse) => {
        const users = response.data;
        success ? success(users) : resolve(users);
      })
      .catch((err: AxiosError) => {
        const error = extractErrorMessage(err);
        if (failure) failure([new Error(error)]);
        reject([error]);
      });
  });
}

export function createuser(
  formdata: Record<string, string | number | number[] | string[]>,
  success?: () => void,
  failure?: (err: Error[]) => void
) {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/v1/users",
      method: "POST",
      data: formdata
    })
      .then(() => {
        resolve(success ? success() : true);
      })
      .catch((err: AxiosError) => {
        const error = extractErrorMessage(err);
        if (failure) failure([new Error(error)]);
        reject([error]);
      });
  });
}

export function getcategories() {
  return new Promise<Category[]>((resolve, reject) => {
    axios({
      url: "/api/v1/categories",
      method: "GET"
    })
      .then((response: AxiosResponse<Category[]>) => resolve(response.data))
      .catch((err: AxiosError) => {
        reject(extractErrorMessage(err));
      });
  });
}

export function getclaims() {
  return new Promise<Claim[]>((resolve, reject) => {
    axios({
      url: "/api/v1/claims",
      method: "GET"
    })
      .then((response: AxiosResponse<Claim[]>) => resolve(response.data))
      .catch((err: AxiosError) => {
        reject(extractErrorMessage(err));
      });
  });
}

export function getclaimrequests() {
  return new Promise<ClaimRequest[]>((resolve, reject) => {
    axios({
      url: "/api/v1/claim-requests",
      method: "GET"
    })
      .then((response: AxiosResponse<ClaimRequest[]>) => resolve(response.data))
      .catch((err: AxiosError) => {
        reject(extractErrorMessage(err));
      });
  });
}

export function getroles() {
  return new Promise<Role[]>((resolve, reject) => {
    axios({
      url: "/api/v1/users/roles",
      method: "GET"
    })
      .then((response: AxiosResponse<Role[]>) => resolve(response.data))
      .catch((err: AxiosError) => {
        reject(extractErrorMessage(err));
      });
  });
}

export function getcallbacks() {
  return new Promise<Callback[]>((resolve, reject) => {
    axios({
      url: "/api/v1/callbacks",
      method: "GET"
    })
      .then((response: AxiosResponse<Callback[]>) => resolve(response.data))
      .catch((err: AxiosError) => {
        reject(extractErrorMessage(err));
      });
  });
}

export async function getCSRFToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    axios({
      url: "/api/v1/security/csrf-token",
      method: "GET"
    })
      .then((response: AxiosResponse<{ _csrf: string }>) =>
        resolve(response.data._csrf)
      )
      .catch((err: AxiosError) => {
        reject(extractErrorMessage(err));
      });
  });
}

export function successNotification(
  vue: any,
  message: string,
  duration?: number
) {
  vue.$buefy.toast.open({
    type: "is-success",
    message,
    duration: isNaN(Number(duration)) ? 1000 : duration
  });
}

export function failureNotification(
  vue: any,
  message: string,
  duration?: number
) {
  vue.$buefy.toast.open({
    type: "is-danger",
    message,
    duration: isNaN(Number(duration)) ? 1000 : duration
  });
}

export function warningNotification(
  vue: any,
  message: string,
  duration?: number
) {
  vue.$buefy.toast.open({
    type: "is-warning",
    message,
    duration: isNaN(Number(duration)) ? 1000 : duration
  });
}

export function infoNotification(vue: any, message: string, duration?: number) {
  vue.$buefy.toast.open({
    type: "is-info",
    message,
    duration: isNaN(Number(duration)) ? 1000 : duration
  });
}
