






















import { Component, Vue } from "vue-property-decorator";
import SupportTicketForm from "@views/components/SupportTicketForm.vue";

@Component({
  components: {
    SupportTicketForm
  }
})
export default class Footer extends Vue {
  supportmodal: boolean = false;
}
