










import { Component, Vue, Watch } from "vue-property-decorator";
import Footer from "@views/components/Footer.vue";
import { bootstrap, cleanup } from "./services/initilization";
@Component({
  components: {
    Footer
  }
})
export default class App extends Vue {
  get authenticated() {
    return this.$store.getters["session/authenticated"];
  }
  async created() {
    await bootstrap();
  }

  async beforeDestroy() {
    await cleanup();
  }

  @Watch("authenticated", { immediate: true })
  async authenticationStatus(value: boolean) {
    if (!value) {
      await cleanup();
    } else {
      await bootstrap();
    }
  }
}
