





















































































enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  SUCCESS = "success"
}

import { Component, Vue, Prop } from "vue-property-decorator";
import { nonEmptyString } from "../../util/string";
import { getCSRFToken, extractErrorMessage } from "../../services/actions";
import axios from "axios";
import { zonedTimeToUtc } from "date-fns-tz";
import { scrollBehavior } from "../../util/dom";

@Component
export default class SupportTicketForm extends Vue {
  title: string = "";
  notes: string = "";
  csrfToken: string = "";

  loading: boolean = false;
  errors: string[] = [];
  status: Status = Status.IDLE;

  mounted() {
    this.getCSRFToken();
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  get timestamp() {
    return zonedTimeToUtc(
      new Date(),
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ).getTime();
  }

  submit() {
    if (
      nonEmptyString(this.title) &&
      this.title.trim().length >= 2 &&
      nonEmptyString(this.notes) &&
      this.notes.trim().length >= 2
    ) {
      axios({
        url: "/api/v1/support",
        method: "POST",
        data: {
          title: this.title.trim(),
          description: this.notes.trim(),
          timestamp: this.timestamp,
          url: window.location.href,
          _csrf: this.csrfToken
        }
      })
        .then(() => {
          // this.loading = false;
          // this.status = Status.IDLE;
          window.location.reload();
        })
        .catch(err => {
          this.getCSRFToken();
          this.errors = [extractErrorMessage(err)];
          scrollBehavior("#support-ticket-errors");
        });
    } else {
      this.errors = ([] as string[])
        .concat(
          !(nonEmptyString(this.title) && this.title.trim().length >= 2)
            ? ["Valid title is required. Minimum length 2"]
            : []
        )
        .concat(
          !(nonEmptyString(this.notes) && this.notes.trim().length >= 2)
            ? ["Valid description is required. Minimum length 2"]
            : []
        );
      scrollBehavior("#support-ticket-errors");
    }
  }

  clear() {
    this.title = "";
    this.notes = "";
    // @ts-ignore
    this.$parent.close();
  }

  beforeDestroy() {
    this.clear();
  }
}
