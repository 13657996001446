




















import { Component, Vue, Prop } from "vue-property-decorator";
import PrivacyPolicyContent from "./PrivacyPolicyContent.vue";

@Component({
  components: {
    PrivacyPolicyContent
  }
})
export default class LoginPrivacyPolicy extends Vue {
  close() {
    //@ts-ignore
    this.$parent.close();
  }
}
