import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import AppErrorsStore from "@services/store/modules/errors";
import SessionStore from "@services/store/modules/session";

Vue.use(Vuex);

export interface RootState {}

const store: StoreOptions<RootState> = {
  modules: {
    errors: AppErrorsStore,
    session: SessionStore
  },
  plugins: [
    createPersistedState({
      key: "uber-therapy-fund",
      storage: sessionStorage
    })
  ]
};

export default new Vuex.Store(store);
