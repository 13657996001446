import $ from "jquery";
export const scrollBehavior = (element: string | HTMLElement, opts?: any) => {
  const $el =
    element instanceof HTMLElement ? element : document.querySelector(element);

  if (!($el instanceof HTMLElement)) {
    throw new Error("Invalid target dom element argument");
  }

  const doDefaultScroll = ($elToScrollIntoView: HTMLElement) =>
    $elToScrollIntoView.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center"
    });

  const isInModal = ($domElement: HTMLElement) => {
    const $modal = $($domElement).closest(".modal")[0];
    return $modal ? $($modal) : false;
  };

  const $modalParentEl = isInModal($el);

  if ($modalParentEl) {
    $modalParentEl.scrollTop(85);
    $modalParentEl.animate(
      {
        // @ts-ignore
        scrollTop: $($el).offset().top - 85
      },
      "fast"
    );
  } else {
    doDefaultScroll($el);
  }
};
