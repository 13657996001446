






































import { Component, Vue } from "vue-property-decorator";
import LoginForm from "@views/components/LoginForm.vue";
import LoginPrivacyPolicy from "@views/components/LoginPrivacyPolicy.vue";
import Emitter from "../../services/events/event-bus";

@Component({
  components: {
    LoginForm,
    LoginPrivacyPolicy
  }
})
export default class Login extends Vue {
  privacyPolicyModal: boolean = false;
  entered: boolean = false;
  mounted() {
    Emitter.$on("has-entrance-pass", () => {
      this.entered = true;
    });

    Emitter.$on("entrance-pass-revoked", () => {
      this.entered = false;
    });
  }

  private openPrivacyPolicyModal() {
    this.privacyPolicyModal = true;
  }
}
