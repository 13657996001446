export const MAX_EMAIL_ADDRESS_LENGTH = 100;
export const MIN_EMAIL_ADDRESS_LENGTH = 5;
export const MAX_PERSON_NAME_LENGTH = 100;
export const MIN_PERSON_NAME_LENGTH = 1;
export const MAX_PHONE_NUMBER_LENGTH = 18;
export const MIN_PHONE_NUMBER_LENGTH = 10;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 40;

export const LINE_OF_BUSINESS_FILER = new Set(["24115"]);

export const MIN_CLAIM_SID_LENGTH = 7;
export const MAX_CLAIM_SID_LENGTH = 10;
export const MIN_CLAIM_REQUEST_DESCRIPTION_LENGTH = 1;
export const MAX_CLAIM_REQUEST_DESCRIPTION_LENGTH = 500;

export const EMAIL_ADDRESS_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// export const PERSON_NAME_REGEX = /^(?=.{1,100}$)[a-zA-ZÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜäëïöü¡¿çÇßØøÅåÆæÞþÐðºª,-\.'\s]*$/;
export const PERSON_NAME_REGEX = /^(?=.{1,100}$)[a-zA-ZÀ-ÖØ-öø-ÿ,-\.'\s]*$/;
export const PHONE_NUMBER_REGEX = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

// export const PHONE_NUMBER_REGEX = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){10,18}\d$/;

// export const PHONE_NUMBER_REGEX = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g;

export const USER_ROLE_ID = {
  RAINN_ADMIN: 1,
  RAINN_AGENT: 2,
  UBER_ADMIN: 3,
  UBER_AGENT: 4,
  CLAIM_REQUEST_REVIEWER: 5
} as const;
