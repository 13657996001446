import {
  nonEmptyString,
  minlength,
  maxlength,
  isEmail as email,
  equals,
  testPattern as pattern,
  includesPattern as includes,
  isPassword as password,
  isName as name,
  isPhone as phone
} from "@/util/string";

export const rules = {
  string: {
    nonEmptyString,
    minlength,
    maxlength,
    equals,
    pattern,
    includes,
    email,
    password,
    name,
    phone
  }
};
