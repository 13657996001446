






































































































































































import { Component, Vue } from "vue-property-decorator";
import axios, { AxiosResponse, AxiosError } from "axios";
import router from "../../services/router";
import { getCSRFToken } from "../../services/actions";
import { extractErrorMessage } from "../../services/actions";
import { scrollBehavior } from "../../util/dom";
import get from "lodash/get";
import has from "lodash/has";
import flatten from "lodash/flatten";
import { rules } from "../../util/validation";
import { nonEmptyString } from "../../util/string";
import Emitter from "../../services/events/event-bus";

enum Status {
  IDLE = "idle",
  SUBMITTING = "submitting",
  ENTERED = "entered",
  SUCCESS = "success"
}

@Component
export default class LoginForm extends Vue {
  username: string = "";
  password: string = "";
  accesscode: string = "";
  status: Status = Status.IDLE;
  formerrors: Record<string, string[]> = {};
  loading: boolean = false;
  errors: string[] = [];
  csrfToken: string = "";

  mounted() {
    this.accessCodePolicy();
    this.getCSRFToken();
  }

  getCSRFToken() {
    getCSRFToken()
      .then(token => (this.csrfToken = token))
      .catch(err => console.error("Failure to get csrf token", err));
  }

  accessCodePolicy() {
    axios({
      url: `/api/v1/security/access-code-policy-check`,
      method: "GET"
    })
      .then((response: AxiosResponse) => {
        if (response.data === false) {
          this.status = Status.ENTERED;
          Emitter.$emit("has-entrance-pass");
          return;
        }

        this.status = Status.IDLE;
        Emitter.$emit("entrance-pass-revoked");
      })
      .catch(err => {
        this.status = Status.IDLE;
        Emitter.$emit("entrance-pass-revoked");
        const error = extractErrorMessage(err);
        this.errors = [error];
        scrollBehavior("#login-errors");
      });
  }

  validation() {
    this.formerrors = ["usernam", "password"].reduce(
      (errorbag: Record<string, string[]>, field: string) => {
        switch (field) {
          case "username": {
            const isvalid = rules.string.email(get(this.$data, field));
            return isvalid
              ? errorbag
              : { ...errorbag, email: ["Valid email address is required"] };
          }

          case "password": {
            const isvalid = rules.string.password(get(this.$data, field));
            return isvalid
              ? errorbag
              : { ...errorbag, password: ["Valid password is required"] };
          }

          default: {
            return errorbag;
          }
        }
      },
      {} as Record<string, string[]>
    );
  }

  reset() {
    this.username = "";
    this.password = "";
    this.status = Status.ENTERED;
    this.loading = false;
    this.errors = [];
    this.formerrors = {};
    // Emitter.$emit("entrance-pass-revoked");
    const $form = document.getElementById("user-login") as HTMLFormElement;
    if ($form) {
      $form.reset();
      $form.noValidate = true;
      $form.noValidate = false;
    }
  }

  revokeaccesscode() {
    axios({
      url: `/api/v1/users/authentication`,
      method: "DELETE",
      headers: {
        "X-CSRF-Token": this.csrfToken
      }
    })
      .then(() => {
        Emitter.$emit("entrance-pass-revoked");
      })
      .catch(err => {
        this.getCSRFToken();
        const error = extractErrorMessage(err);
        this.errors = [error];
        scrollBehavior("#login-errors");
      });
  }

  enter() {
    if (nonEmptyString(this.accesscode.trim())) {
      // this.status = Status.SUBMITTING;
      this.errors = [];
      this.loading = true;
      axios({
        url: "/api/v1/users/entrance",
        method: "POST",
        data: {
          accesscode: this.accesscode,
          _csrf: this.csrfToken
        }
      })
        .then((response: AxiosResponse) => {
          this.getCSRFToken();
          this.status = Status.ENTERED;
          this.loading = false;
          Emitter.$emit("has-entrance-pass");
        })
        .catch((err: AxiosError) => {
          this.getCSRFToken();
          Emitter.$emit("entrance-pass-revoked");
          this.status = Status.IDLE;
          this.loading = false;
          const error = extractErrorMessage(err);
          this.errors = [error];
          scrollBehavior("#login-errors");
          console.debug("[entrance request] Error", err.response);
        });
    } else {
      this.errors = ["Access code required"];
      scrollBehavior("#login-errors");
    }
  }

  submit() {
    this.status = Status.SUBMITTING;
    this.errors = [];
    this.loading = true;

    this.validation();

    if (Object.keys(this.formerrors).length) {
      this.errors = flatten(Object.values(this.formerrors));
      this.status = Status.ENTERED;
      this.loading = false;
      return;
    }

    axios({
      url: "/api/v1/users/authentication",
      method: "POST",
      data: {
        username: this.username.trim(),
        password: this.password,
        _csrf: this.csrfToken
      }
    })
      .then((response: AxiosResponse) => {
        this.status = Status.SUCCESS;
        this.loading = false;
        this.$store.dispatch("session/update", {
          authenticated: true,
          user: response.data.userinfo
        });
        router.push(response.data.redirect);
      })
      .catch((err: AxiosError) => {
        this.getCSRFToken();
        this.status = Status.ENTERED;
        this.loading = false;
        const error = extractErrorMessage(err);
        this.errors = [error];
        scrollBehavior("#login-errors");
        console.debug("[authentication request] Error", err.response);
      });
  }
}
