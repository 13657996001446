import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH
} from "./../services/config/constants";
import {
  MAX_EMAIL_ADDRESS_LENGTH,
  MIN_EMAIL_ADDRESS_LENGTH,
  EMAIL_ADDRESS_REGEX,
  MAX_PERSON_NAME_LENGTH,
  MIN_PERSON_NAME_LENGTH,
  PERSON_NAME_REGEX,
  PHONE_NUMBER_REGEX,
  MIN_PHONE_NUMBER_LENGTH,
  MAX_PHONE_NUMBER_LENGTH
} from "@/services/config/constants";

import { parsePhoneNumberFromString } from "libphonenumber-js";

export const isString = (value: unknown): value is string =>
  typeof value === "string";

export const nonEmptyString = (value: unknown): value is string =>
  Boolean(isString(value) && value.trim().length);

export const minlength = (value: unknown, length: number) =>
  isString(value) && value.trim().length >= length;

export const maxlength = (value: unknown, length: number) =>
  isString(value) && value.trim().length <= length;

export const equals = (a: unknown, b: unknown) =>
  isString(a) && isString(b) && a.trim() === b.trim();

export const includesPattern = (value: unknown, re: RegExp, count?: number) => {
  if (!isString(value)) return false;
  const matches = value.match(re);
  if (!Array.isArray(matches)) return false;
  const _count = count !== null && !isNaN(Number(count)) ? Number(count) : 1;
  return matches.length >= _count;
};

export const testPattern = (value: unknown, re: RegExp) => {
  if (!isString(value)) return false;
  return re.test(value);
};

export const isEmail = (value: unknown) => {
  if (!nonEmptyString(value)) return false;
  const email = value.trim();

  return (
    email.length <= MAX_EMAIL_ADDRESS_LENGTH &&
    email.length >= MIN_EMAIL_ADDRESS_LENGTH &&
    // email.includes("@") &&
    // email.includes(".") &&
    EMAIL_ADDRESS_REGEX.test(email)
  );
};

export const isName = (value: unknown) => {
  if (!nonEmptyString(value)) return false;
  const name = removeDiacritics(value.trim());
  console.debug("name validation with input (diacritics chars removed)", name);
  return (
    name.length <= MAX_PERSON_NAME_LENGTH &&
    name.length >= MIN_PERSON_NAME_LENGTH &&
    PERSON_NAME_REGEX.test(name)
  );
};

export const isPhone = (value: unknown) => {
  if (!nonEmptyString(value)) return false;
  const phone = value.trim();
  const parsed = parsePhoneNumberFromString(phone);
  return (
    phone.length <= MAX_PHONE_NUMBER_LENGTH &&
    phone.length >= MIN_PHONE_NUMBER_LENGTH &&
    (PHONE_NUMBER_REGEX.test(phone) || (parsed ? parsed.isValid() : false))
  );
};

export const isPassword = (value: unknown) => {
  return (
    nonEmptyString(value) &&
    minlength(value, MIN_PASSWORD_LENGTH) &&
    maxlength(value, MAX_PASSWORD_LENGTH) &&
    /** At least 1 uppercase letter */
    includesPattern(value, /.*[A-Z].*/g) &&
    /** At least 1 lowercase letter */
    includesPattern(value, /.*[a-z].*/g) &&
    /** At least 1 digit */
    includesPattern(value, /.*[1-9].*/g) &&
    /** At least 1 special character */
    includesPattern(value, /.*[!@#$%^&*()_+=-~./?><,.].*/g)
  );
};

export const removeDiacritics = (value: unknown): string => {
  if (!isString(value)) return "";
  const ACCENTS =
    "ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪĮìíîïīįÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğ";
  const NON_ACCENTS =
    "AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIIiiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGgg";
  const strAccents: string[] = value.split("");
  const strAccentsOut: string[] = new Array();
  const strAccentsLen: number = strAccents.length;
  for (let y = 0; y < strAccentsLen; y++) {
    if (ACCENTS.indexOf(strAccents[y]) != -1) {
      strAccentsOut[y] = NON_ACCENTS.substr(ACCENTS.indexOf(strAccents[y]), 1);
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }
  const newString: string = strAccentsOut.join("");
  return newString;

  // return value
  //   .trim()
  //   .normalize("NFD")
  //   .replace(/[\u0300-\u036f]/g, "");
};
