import Vue from "vue";
import App from "@/App.vue";

import Buefy from "buefy";
import "buefy/dist/buefy.css";

import "@resources/styles/app.scss";

Vue.use(Buefy, { defaultIconPack: "mdi" });

/* Bootstrapping services */
import router from "@services/router";
import store from "@services/store";

Vue.config.productionTip = process.env.NODE_ENV !== "development";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
